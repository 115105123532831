<template>
  <v-simple-table dense class="pb-2 mt-4">
    <tbody>
      <tr>
        <th>{{ $t("endpoint ID") }}</th>
        <td>
          {{ item.endpoint_id ? item.endpoint_id : "-" }}
        </td>
      </tr>

      <tr>
        <th>{{ $t("user agent") }}</th>
        <td>
          {{ item.user_agent ? item.user_agent : "-" }}
        </td>
      </tr>

      <tr>
        <th>{{ $t("received address") }}</th>
        <td>
          {{ item.received_address ? item.received_address : "-" }}
        </td>
      </tr>

      <tr>
        <th>{{ $t("behind NAT") }}</th>
        <td>
          <boolean-value :value="item.behind_nat" />
        </td>
      </tr>

      <tr>
        <th>{{ $t("expiration reported") }}</th>
        <td>
          <boolean-value :value="item.expiration_reported" />
        </td>
      </tr>

      <tr>
        <th>{{ $t("q") }}</th>
        <td>
          {{ item.q ? item.q : "-" }}
        </td>
      </tr>

      <tr>
        <th>{{ $t("instance ID") }}</th>
        <td>
          {{ item.instance_id ? item.instance_id : "-" }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import formatMoney from "@/utils/mixins/formatMoney";
import formatNumber from "@/utils/mixins/formatNumber";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import BooleanValue from "@/components/basics/BooleanValue";

export default {
  name: "SipRegistration",
  mixins: [formatDate, formatMoney, formatNumber, showErrors, isMobile],
  components: {
    BooleanValue,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    item: null,
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.item = { ...value };
        }
      },
    },
  },
};
</script>

<i18n>
{
  "en": {
    "endpoint ID": "Endpoint ID",
    "user agent": "User agent",
    "received address": "Received address",
    "behind NAT": "Behind NAT",
    "expiration reported": "Expiration reported",
    "q": "Q",
    "instance ID": "Instance ID"
  },
  "de": {
    "endpoint ID": "Endpoint ID",
    "user agent": "User Agent",
    "received address": "Empfangene Adresse",
    "behind NAT": "Behind NAT",
    "expiration reported": "Ablauf reported",
    "q": "Q",
    "instance ID": "Instanz ID"
  }
}
</i18n>
