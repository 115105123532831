<template>
  <v-card disabled flat>
    <v-simple-table dense class="pb-2 mt-4">
      <tbody>
        <tr>
          <th>{{ $t("active") }}</th>
          <td>
            <boolean-value :value="item.active" />
          </td>
        </tr>

        <tr>
          <th>{{ $t("monitoring charge") }}</th>
          <td>
            {{
              item.monitoring_charge
                ? formatNumber(item.monitoring_charge)
                : "-"
            }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("maximum charge") }}</th>
          <td>
            {{ item.maximum_charge ? formatNumber(item.maximum_charge) : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("monthly reset") }}</th>
          <td>
            {{ item.monthly_reset ? formatNumber(item.monthly_reset) : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("alarm level") }}</th>
          <td>
            {{ item.alarm_level ? item.alarm_level : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("alarm email") }}</th>
          <td>
            {{ item.alarm_email ? item.alarm_email : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("daily top stop") }}</th>
          <td>
            {{ item.daily_top_stop ? item.daily_top_stop : "-" }}
          </td>
        </tr>

        <tr>
          <th>{{ $t("daily maximum charge") }}</th>
          <td>
            {{
              item.daily_maximum_charge
                ? formatNumber(item.daily_maximum_charge)
                : "-"
            }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import formatMoney from "@/utils/mixins/formatMoney";
import formatNumber from "@/utils/mixins/formatNumber";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import BooleanValue from "@/components/basics/BooleanValue";

export default {
  name: "TopStop",
  mixins: [formatDate, formatMoney, formatNumber, showErrors, isMobile],
  components: {
    BooleanValue,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    item: null,
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.item = { ...value };
        }
      },
    },
  },
};
</script>

<i18n>
{
  "en": {
    "active": "Active",
    "monitoring charge": "Monitoring charge",
    "maximum charge": "Maximum charge",
    "monthly reset": "Monthly reset",
    "alarm level": "Alarm level",
    "alarm email": "Alarm email",
    "daily top stop": "Daily top stop",
    "daily maximum charge": "Daily maximum charge"
  },
  "de": {
    "active": "Aktiv",
    "monitoring charge": "Überwachungsgebühr",
    "maximum charge": "Maximale Ladung",
    "monthly reset": "Monatliche Zurücksetzung",
    "alarm level": "Alarmstufe",
    "alarm email": "Alarm Email",
    "daily top stop": "Täglicher Top-Stopp",
    "daily maximum charge": "Tägliche Höchstgebühr"
  }
}
</i18n>
