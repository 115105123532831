<template>
  <v-card class="my-4" outlined :loading="loading">
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("VoIP subscription") }}
      </v-toolbar-title>
      <v-spacer />

      <v-tooltip top v-if="item != null">
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="loading"
            :loading="loading"
            v-on="on"
            @click="getItem();"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>

      <v-tooltip top v-if="item != null && item.product_id">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              $router.push({
                name: 'product-details',
                params: { productId: productId },
              })
            "
            icon
          >
            <v-icon>mdi-text-box</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("jump to product") }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-progress-linear :active="loading" />

    <v-card-text>
      <v-card v-if="item && item.subscription" class="mb-4" outlined>
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("subscription") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-simple-table dense class="ma-4">
          <tbody>
            <tr>
              <th>{{ $t("subscription number") }}</th>
              <td>{{ item.subscription.id }}</td>
            </tr>
            <tr>
              <th>{{ $t("type") }}</th>
              <td>{{ item.subscription.type || "-" }}</td>
            </tr>
            <tr v-if="!isVPBX">
              <th>{{ $t("SIP username") }}</th>
              <td>{{ item.subscription.username || "-" }}</td>
            </tr>
            <tr v-if="!isVPBX">
              <th>{{ $t("SIP password") }}</th>
              <td>{{ item.subscription.password || "-" }}</td>
            </tr>
            <tr v-if="!isVPBX">
              <th>{{ $t("SIP server") }}</th>
              <td>sip01.iway.ch</td>
            </tr>
            <tr>
              <th>{{ $t("start date") }}</th>
              <td>
                {{
                  item.subscription.start_date
                    ? formatDate(item.subscription.start_date)
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("end date") }}</th>
              <td>
                {{
                  item.subscription.end_date
                    ? formatDate(item.subscription.end_date)
                    : "-"
                }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <v-card
        v-if="
          item &&
          item.subscription &&
          (item.subscription.teams_name != null ||
            item.subscription.teams_value != null)
        "
        class="mb-4"
        outlined
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("MS teams") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-simple-table dense class="pb-2 mt-4">
          <tbody>
            <tr>
              <th>{{ $t("teams name") }}</th>
              <td>{{ item.subscription.teams_name || "-" }}</td>
            </tr>

            <tr>
              <th>{{ $t("teams value") }}</th>
              <td>{{ item.subscription.teams_value || "-" }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <v-card
        v-if="item && item.subscription && item.subscription.vpbx_name"
        class="mb-4"
        outlined
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("vpbx") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-simple-table dense class="pb-2 mt-4">
          <tbody>
            <tr>
              <th>{{ $t("name") }}</th>
              <td>
                <a :href="'https://' +
                  (item.subscription.vpbx_name || '') + '.' + (item.subscription.vpbx_domain ? item.subscription.vpbx_domain.domain : '')
                ">{{
                  (item.subscription.vpbx_name || "") +
                  "." +
                  (item.subscription.vpbx_domain
                    ? item.subscription.vpbx_domain.domain
                    : "")
                }}</a>

              </td>
            </tr>

            <template v-if="item.subscription.vpbx_config">
              <tr>
                <th>{{ $t("user") }}</th>
                <td>
                  <pre>{{ item.subscription.vpbx_config.user }}</pre>
                </td>
              </tr>
              <tr>
                <th>{{ $t("password") }}</th>
                <td>
                  <pre>{{ item.subscription.vpbx_config.password }}</pre>
                </td>
              </tr>

            </template>


          </tbody>
        </v-simple-table>
      </v-card>

      <!-- <v-card
        v-if="item && item.subscription && item.subscription.top_stop"
        class="mb-4"
        outlined
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("top stop") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <top-stop :value="item.subscription.top_stop" />
        <v-simple-table dense class="pb-2 mt-4" />
      </v-card> -->

      <!-- <v-card
        v-if="item && item.subscription && item.subscription.call_forwards"
        class="mb-4"
        outlined
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("call forwards") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(cf, key) in item.subscription.call_forwards"
            :key="key"
          >
            <v-expansion-panel-header>
              <b>{{ cf.name }} </b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <call-forward :value="cf" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card> -->

      <!-- <v-card
        v-if="item && item.subscription && item.subscription.call_forwards"
        class="mb-4"
        outlined
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("numbers") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-expansion-panels>
          <v-expansion-panel v-for="(num, key) in numbers" :key="key">
            <v-expansion-panel-header>
              <b>{{ num.national_number }}</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <number :value="num" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card> -->
    </v-card-text>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import formatMoney from "@/utils/mixins/formatMoney";
import formatNumber from "@/utils/mixins/formatNumber";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import BooleanValue from "@/components/basics/BooleanValue";
import TopStop from "@/components/services/voip/TopStop";
import CallForward from "@/components/services/voip/CallForward";
import EmergencyContact from "@/components/services/voip/EmergencyContact";
import Number from "@/components/services/voip/Number";

export default {
  name: "TelephonyVoipDetails",
  mixins: [formatDate, formatMoney, formatNumber, showErrors, isMobile],
  components: {
    BooleanValue,
    TopStop,
    CallForward,
    EmergencyContact,
    Number,
  },
  props: {
    productId: {
      validator: (prop) => typeof prop === "string" || typeof prop === "number",
    },
  },
  data: () => ({
    item: null,
    loadingItem: false,
    loadingNumbers: false,
    numbers: [],
  }),
  watch: {
    productId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getItem();
          // this.getNumbers();
        }
      },
    },
  },
  computed: {
    loading() {
      return this.loadingItem || this.loadingNumbers;
    },
    isVPBX() {
      return (
        this.item &&
        this.item.subscription &&
        this.item.subscription.vpbx_name
      );
    },
  },
  methods: {
    getItem() {
      var that = this;
      that.loadingItem = true;
      this.$http
        .get("services/voip/" + this.productId)
        .then((response) => {
          that.item = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingItem = false;
        });
    },
    getNumbers() {
      var that = this;
      that.loadingNumbers = true;
      this.$http
        .get("services/voip/" + this.productId + "/numbers")
        .then((response) => {
          that.numbers = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingNumbers = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "VoIP details": "VoIP Details",
    "VoIP subscription": "VoIP Subscription",
    "reload": "reload",
    "jump to product": "jump to product",
    "subscription": "Subscription",
    "subscription number": "Subscription number",
    "customer reference": "Customer reference",
    "type": "Type",
    "SIP username": "SIP username",
    "SIP password": "SIP password",
    "SIP server": "SIP server",
    "start date": "Start date",
    "end date": "End date",
    "MS teams": "MS teams",
    "teams name": "Teams name",
    "teams value": "Teams value",
    "top stop": "Top stop",
    "vpbx": "VPBX",
    "name": "name",
    "max CTI": "max. CTI",
    "max user": "max. user",
    "priority": "Priority",
    "call forwards": "Call forwards",
    "numbers": "Numbers",
    "user": "User",
    "password": "Password"
  },
  "de": {
    "VoIP details": "VoIP Details",
    "VoIP subscription": "VoIP Abonnement",
    "reload": "aktualisieren",
    "jump to product": "zum Produkt wechseln",
    "subscription": "Abonnement",
    "subscription number": "Abonnement Nummer",
    "customer reference": "Kundenreferenz",
    "type": "Typ",
    "SIP username": "SIP Username",
    "SIP password": "SIP Passwort",
    "SIP server": "SIP Server",
    "start date": "Startdatum",
    "end date": "Enddatum",
    "MS teams": "MS Teams",
    "teams name": "Teams Name",
    "teams value": "Teams Wert",
    "top stop": "Top Stop",
    "vpbx": "VPBX",
    "name": "Name",
    "max CTI": "max. CTI",
    "max user": "max. User",
    "priority": "Priorität",
    "call forwards": "Anrufweiterleitung",
    "numbers": "Rufnummern",
    "user": "Benutzer",
    "password": "Passwort"
  }
}
</i18n>

<!-- Example Product -->
<!-- {
  "product_id": 0,
  "product_name": "string",
  "product_descr": "string",
  "subscription_id": "string",
  "subscription": {
    "id": 0,
    "description": "string",
    "type": "string",
    "customer_reference": "string",
    "username": "string",
    "password": "string",
    "teams_name": "string",
    "teams_value": "string",
    "vpbx_name": "string",
    "vpbx_max_user": 0,
    "vpbx_max_cli": 0,
    "system": "string",
    "status": 0,
    "start_date": "2025-03-27",
    "end_date": "2025-03-27",
    "top_stop": {
      "active": true,
      "monitoring_charge": 0,
      "maximum_charge": 0,
      "monthly_reset": 0,
      "alarm_level": 0,
      "alarm_email": "string",
      "daily_top_stop": 0,
      "daily_maximum_charge": 0
    },
    "vpbx_domain": {
      "domain": "string",
      "priority": 0
    },
    "add_on_packages": [
      {
        "region_name": "string",
        "product_name": "string",
        "name": "string",
        "code": "string",
        "description": "string",
        "included_minutes": 0,
        "included_data_kb": 0,
        "included_sms": 0,
        "price": 0,
        "duration_days": 0,
        "start_date": "2025-03-27",
        "end_date": "2025-03-27"
      }
    ],
    "region_allowances": [
      {
        "region_allowance_id": 0,
        "start_date": "2025-03-27",
        "end_date": "2025-03-27"
      }
    ],
    "call_forwards": [
      {
        "name": "string",
        "type": "CFU",
        "priority": 0,
        "dest_pattern": "string",
        "source_pattern": "string",
        "source_presentation_pattern": "string",
        "dest_replace": "string",
        "time_pattern": "string",
        "reject_pattern": "string",
        "delay": 0,
        "propagate_busy": true,
        "parallel_call": true,
        "always_ring": true,
        "early_media": true,
        "last_diversion": true,
        "reroute": true,
        "suspended": true,
        "disabled": true,
        "start_date": "2025-03-27",
        "end_date": "2025-03-27"
      }
    ]
  }
} -->

<!-- Example Numbers -->
<!-- {
  "id": 0,
  "national_number": "string",
  "is_main": true,
  "block_size": 0,
  "top_stop": {
    "active": true,
    "monitoring_charge": 0,
    "maximum_charge": 0,
    "monthly_reset": 0,
    "alarm_level": 0,
    "alarm_email": "string",
    "daily_top_stop": 0,
    "daily_maximum_charge": 0
  },
  "emergency_contact": {
    "address": "string",
    "longitude": "string",
    "latitude": "string",
    "created_at": "2025-03-27T16:14:15.244Z",
    "created_by": "string"
  },
  "sip_registrations": [
    {
      "endpoint_id": 0
      "user_agent":	"string",
      "received_address": "string",
      "behind_nat": true,
      "expiration_reported": false,
      "q": 0,
      "instance_id": "string"
    }
  ]
} -->